import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from "../../contexts/Auth.context";

// from https://developers.heap.io/docs/using-identify#using-a-non-unique-identifier
const invalidIdentifiers = [
  'anonymous',
  'guest',
  undefined,
  null,
  true,
  false,
  'not_authenticated',
  (v) => Number.isNaN(v),
  '',
  0,
  '[object Object]',
  'None',
  'none',
  'Email',
  'email'
]

const isValidIdentifier = (id) => !invalidIdentifiers.some((identifier) => {
  if (typeof identifier === 'string') {
    return id.toLowerCase() === identifier
  } if (typeof identifier === 'function') {
    return identifier(id)
  } else {
    return id === identifier
  }
})

export const HeapIntegration = () => {
  const { agentId: userId = null, agentDRE, agentName = null, agentEmail } = useContext(AuthContext)
  const [userSessionActive, setUserSessionActive] = useState(false)
  useEffect(() => {
    if (window && window.hasOwnProperty('heap') && window.heap) {
      const { heap } = window
      // if have a new and valid id to identify the user session and associate:
      if (userId && userId.toString().length && isValidIdentifier(userId)) {
        if (heap.hasOwnProperty('identify') && typeof heap.identify === 'function') {
          heap.identify(userId.toString())
          setUserSessionActive(true)
          if (
            heap.hasOwnProperty('addUserProperties') &&
            typeof heap.addUserProperties === 'function' &&
            agentName.toString().length
          ) {
            heap.addUserProperties({ agentName, agentEmail, agentDRE })
          }
        } else {
          console.error('invalid Heap Identify method signature')
        }
      // if the user have logged out and the userId is null, reset the user session
      } else if (userId === null && userSessionActive) {
        if (heap.hasOwnProperty('resetIdentity') && typeof heap.resetIdentity === 'function') {
          heap.resetIdentity()
          setUserSessionActive(false)
        } else {
          console.error('invalid Heap Identify method signature')
        }
      }
    }
  }, [userId])
  return (<></>)
}
