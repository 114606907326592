import React, { useContext, useEffect, useState } from 'react';
import { AutosaveProvider } from 'avenue8-autosave';
import { AuthContext } from '../contexts/Auth.context';
import { authBaseURL } from '../services/api';

const AutosaveProviderWrapper = ({ children }) => {
  const [ authSbl ] = useContext(AuthContext);
  const [urlToken, setUrlToken] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!authSbl?.token && queryParams.has('token')) {
      const token = queryParams.get('token');
      setUrlToken(token);
    }
  }, [authSbl]);

  return authSbl.token || urlToken ? (
    <AutosaveProvider
      connectionConfig={{
        authServiceUrl: authBaseURL,
        avenue8Token: authSbl.token || urlToken
      }}
    >
      { children }
    </AutosaveProvider>
  ) : children;
}

export default AutosaveProviderWrapper;
