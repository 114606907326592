import { useContext, useEffect } from 'react'
import { actionTypes, AuthContext } from '../contexts/Auth.context'
import { getAgentData } from '../services/auth'

export const useAgentData = () => {
  const [ authData, dispatch ]  = useContext(AuthContext)

  const getAgentDataFromApi = async (token) => {
    const { firstName, id, dre, email } = await getAgentData(token)
    return {
      agentName: firstName,
      agentId: id,
      agentDRE: dre,
      agentEmail: email,
    }
  }

  useEffect(() => {
    const getDataIfContextIsNotFilled = async () => {
      const isAgentDataFilled = Object.entries(authData)
        .filter(([ key ]) => key.match(/agent/))
        .every(([ , value ]) => value !== '' && value !== null && value !== undefined)

      const { token } = authData
      if (!isAgentDataFilled && token) {
        const agentDataUpdated = await getAgentDataFromApi(token)
          .catch(error => {
            if (/401/.test(error.message)) {
              console.error('invalid token')
            }
            throw error
          })

        if (agentDataUpdated) {
          dispatch({
            type: actionTypes.UPDATE_CREDENTIALS,
            payload: agentDataUpdated
          })
        } else {
          dispatch({ type: actionTypes.LOG_OUT })
          window.location.href = `${window.location.origin}/login`
        }
      }
    }

    getDataIfContextIsNotFilled()
  }, [authData.token])

  return authData
}
