import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/Auth.context'
import { getIntercomData } from '../services/intercom'

const useIntercomApi = () => {
  const [ intercomResponse, setIntercomResponse ] = useState({})
  const [ { token } ] = useContext(AuthContext)

  useEffect(() => {
    const getData = async () => {
      if (token) {
        try {
          const data = await getIntercomData(token)
          setIntercomResponse(data)
        } catch (err) {
          if (err.message.match(/401/)) {
            return {}
          }
        }
      }
    }

    getData()
  }, [ token ])

  return intercomResponse
}

export default useIntercomApi
