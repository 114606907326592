import { createGlobalStyle } from 'styled-components'
import './fonts/bookmania.css';
import './fonts/styrenea.css';

// Colors
const blue = '#2a29a6'
const black = '#292929'
const lightBlue = '#dddfef'
const red = '#e93e57'
const white = '#ffffff'

export default createGlobalStyle`
  :root {
    --blue: ${blue};
    --blue-opaque: ${blue}80;
    --black: ${black};
    --lightBlue: ${lightBlue};
    --red: ${red};
    --white: ${white};
  }

  * {
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  body {
    margin: 0px !important;
    /* width: 375px !important; */
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
  }
`
