// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { actionTypes, AuthContext } from "../contexts/Auth.context";

const LoginRedirect = () => {
  const history = useHistory()
  const [{ token, authLoading }, dispatch] = useContext(AuthContext)

  useEffect(() => {
    const { search = '' } = window.location
    const queryParams = new URLSearchParams(search)

    if (queryParams.has('error')) {
      history.replace(`/account/unauthorized?error=${queryParams.get('error')}`)
    } else {
      if (authLoading || !!token) {
        history.replace('/account')
      } else if (!token) {
        window.location.href = window.location.origin
      }
    }

  }, [token, authLoading, history])

  return (<></>)
}

export default LoginRedirect
