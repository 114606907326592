import React from 'react';
import Theme from './assets/theme'
import Routes from './routes/index'
import { AuthContextProvider } from "./contexts/Auth.context";
import {HeapIntegration} from "./components/behavior/HeapIntegration";

function App() {
  return (
    <>
      <AuthContextProvider>
        <HeapIntegration/>
        <Routes />
        <Theme />
      </AuthContextProvider>
    </>
  );
}

export default App;
