import React, { useEffect, useState } from 'react'
// import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { useRequestsCount } from 'avenue8-autosave';
import { Typography } from 'av8-ui';

import ArrowRightIcon from '../assets/icons/Arrow_Right.svg'
import { useAgentData } from '../hooks/useAgentData'
import Loader from '../components/Loader'
import DiveBackInModalWrapper from '../components/DiveBackInModalWrapper';
import AutosaveProviderWrapper from '../components/AutosaveProviderWrapper';
import { useFeatureToggle } from "../hooks/feature";
import { FeatureFlagsEnum } from "../helpers/constants/FeatureFlags.enum";

const homePageStyle = {
  container: {
    '--header-height': '150px',
    '--footer-height': '51.5px',
    '--header-footer-height': 'calc(var(--header-height, 0) + var(--footer-height, 0))',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    minHeight: 'max(22em, calc(100vh - calc(var(--header-footer-height, 0) + 40px )))',
    position: 'relative',
    zIndex: 1
  },
  centeredCell: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'max(1.6em, 5vh)',
  },
  agentNameText: {
    color: 'var(--black)',
    fontSize: '35px',
    fontStyle: 'italic',
    fontWeight: '500',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  greetingText: {
    fontSize: '35px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: '10px',
    fontSize: '23px',
    fontWeight: '400',
    lineHeight: '25px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  links: {
    width: '300px',
    borderRadius: '6px',
    backgroundColor: 'var(--blue)',
    marginTop: '20px',
    padding: '2px 0',
    fontFamily: 'StyreneA',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.25px',
    fontWeight: 500,
    '& > li > a': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      minWidth: '16em',
      marginLeft: '20px',
      padding: '1px 0',
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#FFF',
      '& > span': {
        flexGrow: 1,
        padding: '23px 0 22px 0'
      },
      '& > button': {
        marginRight: 8,
      }
    },
    '& > li:last-child > a': {
      borderBottom: '0px'
    }
  }
}

const links = {
  marketing: {
    title: 'Marketing Request',
    href: '/account/marketing'
  },
  signage: {
    title: 'Outdoor Signage',
    href: '/account/signage'
  },
  cmaRequestFlow: {
    title: 'Get Comps',
    href: '/account/cma/request',
    needTokenInUrl: true,
  },
  content: {
    title: 'Content Library',
    href: '/account/content'
  }
}

const getGreeting = () => {
  const currentHours = new Date().getHours();
  let greeting = 'Hello';
  if (currentHours < 12) {
    greeting = 'Good Morning';
  } else if (currentHours >= 12 && currentHours < 18) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }
  return greeting;
};

const useStyles = makeStyles(homePageStyle)

const Home = () => {
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const { agentId, token, agentName } = useAgentData()
  const { enabled: isAutoSaveEnabled } = useFeatureToggle(FeatureFlagsEnum.autoSave)
  const marketingFlowAuthQueryParams = new URLSearchParams({ agentId, token })

  const requestsCount = useRequestsCount();
  const linkList = {...links}

  if (isAutoSaveEnabled) {
    linkList.draft = {
      title: 'Draft Requests (0)',
      href: '/account/draft-requests'
    }
  }

  useEffect(() => {
    if (agentName) {
      setIsLoading(false)
    }
  }, [agentName])

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DiveBackInModalWrapper />
          <div className={styles.centeredCell}>
            <div className={styles.screenTitle}>
              <Typography
                variant="Title"
                textAlign="center"
                className={styles.greetingText}
              >
                {getGreeting()},
              </Typography>
              <Typography
                variant="Title"
                fontWeight="normal"
                fontStyle="italic"
                textAlign="center"
                className={styles.agentNameText}
              >
                {agentName || 'Agent'}.
              </Typography>
            </div>
            <Typography
              fontWeight="normal"
              fontStyle="italic"
              className={styles.subtitle}
            >
              What can we help you with?
            </Typography>
            <ul className={styles.links}>
              { Object.entries(linkList).map(([key, link]) => (
                <li key={key}>
                  <a href={`${link.href}${link.needTokenInUrl ? '?' + marketingFlowAuthQueryParams : ''}`}>
                    <span>
                      {
                        key !== 'draft'
                          ? link.title
                          : link.title.replace('(0)', `(${requestsCount})`)
                      }
                    </span>
                    <IconButton aria-label="go-forward">
                      <img src={ArrowRightIcon} alt="Go forward button" srcSet="" />
                    </IconButton>
                  </a>
                </li>
              )) }
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

// This component wraps the Home component inside the AutosaveProviderWrapper
// in order to get the amount of draft requests.
const HomeWrapper = () => {
  const { enabled: AutoSaveFeatureToggle } = useFeatureToggle(FeatureFlagsEnum.autoSave)
  if (!AutoSaveFeatureToggle) {
    return <Home/>
  }
  return (
    <AutosaveProviderWrapper>
      <Home />
    </AutosaveProviderWrapper>
  )
}

export default HomeWrapper;
