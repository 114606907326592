import React from 'react'
import ReactLoading from 'react-loading'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export default function Loader({
  type = 'spin',
  color = '#2A29A6',
  sizePx = '55px',
  heightVh = '80vh',
}) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ height: heightVh }}
    >
      <ReactLoading type={type} color={color} height={sizePx} width={sizePx} />
      <Typography type="muted" style={{ marginTop: 8 }}>
        Loading...
      </Typography>
    </Grid>
  );
}
