import { useContext } from 'react';
import { AutosaveModal, NamespaceType, SubFlowType } from 'avenue8-autosave';

import { AuthContext } from '../contexts/Auth.context';

import SocialMediaImage from '../assets/images/SocialMedia.png';
import DisplayAdsImage from '../assets/images/DisplayAds.png';
import EmailBlastImage from '../assets/images/EmailBlast.png';
import PostcardImage from '../assets/images/Postcard.png';
import DoubleSidedFlyerImage from '../assets/images/DoubleSidedFlyer.png';
import BiFoldBrochureImage from '../assets/images/BiFoldBrochure.png';
import SignageImage from '../assets/images/Signage.png';
import CompsImage from '../assets/images/Comps.png';
import AutosaveProviderWrapper from './AutosaveProviderWrapper';
import { handleAutosaveIOSRedirection } from '../services/appServices';

const getLinkByNamespace = (namespace) => {
  switch (namespace) {
    case NamespaceType.Marketing: return '/account/marketing';
    case NamespaceType.Signage: return '/account/signage';
    case NamespaceType.Comps: return '/account/cma/request';
    default: return '';
  }
}

const DiveBackInModalWrapper = () => {
  const [ authSbl ] = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);
  const isEmbedded = queryParams.get('mbd') === 'true';

  const redirectTo = (namespace, timestamp) => {
    if (!isEmbedded) {
      const origin = window.location.origin;
      const namespacePath = getLinkByNamespace(namespace);
  
      if (namespacePath) {
        const agentId = authSbl?.credentials?.agentId;
        const token = authSbl?.credentials?.token;
  
        if (namespace === NamespaceType.Comps) {
          window.location.assign(
            `${origin}${namespacePath}?agentId=${agentId}&token=${token}&draftId=${timestamp}`
          )
        } else {
          window.location.assign(
            `${origin}${namespacePath}?draftId=${timestamp}`
          )
        }
      }
    } else {
      handleAutosaveIOSRedirection(namespace, timestamp);
    }
  }

  const getNamespaceImage = (namespace, subflow) => {
    if (namespace === NamespaceType.Comps) {
      return CompsImage;
    } else {
      switch (subflow) {
        case SubFlowType.SocialMedia:
          return SocialMediaImage;
        case SubFlowType.DisplayAds:
          return DisplayAdsImage;
        case SubFlowType.EmailBlast:
          return EmailBlastImage;
        case SubFlowType.Postcard:
          return PostcardImage;
        case SubFlowType.DoubleSidedFlyer:
          return DoubleSidedFlyerImage;
        case SubFlowType.BiFoldBrochure:
          return BiFoldBrochureImage;
        case SubFlowType.NewSign:
        case SubFlowType.ExistingSign:
          return SignageImage;
        default:
          return '';
      }
    }
  }

  return isEmbedded ? (
    <AutosaveProviderWrapper>
      <AutosaveModal
        redirectTo={redirectTo}
        getNamespaceImage={getNamespaceImage}
      />
    </AutosaveProviderWrapper>
  ) : (
    <AutosaveModal
      redirectTo={redirectTo}
      getNamespaceImage={getNamespaceImage}
    />
  )
}

export default DiveBackInModalWrapper;
