import React, { useContext } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom';
import { RouteWrapper as Route, PublicRouteWrapper as PublicRoute } from './Routes'
import Loader from "../components/Loader";
import Home from '../pages/Home'
import Unauthorized from '../pages/Unauthorized'
import LoginRedirect from '../pages/LoginRedirect'
import { AuthContext } from '../contexts/Auth.context'
import DiveBackInModalWrapper from '../components/DiveBackInModalWrapper';
import { useFeatureToggle } from '../hooks/feature';
import { FeatureFlagsEnum } from '../helpers/constants/FeatureFlags.enum';

export default function Routes() {
  const [{ authLoading }] = useContext(AuthContext)
  const { enabled: isAutoSaveEnabled } = useFeatureToggle(FeatureFlagsEnum.autoSave)
  if (authLoading) {
    return (<Loader></Loader>)
  }
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute showFooter path="/account/unauthorized" exact component={Unauthorized} />
        <PublicRoute showFooter path="/account/login-redirect" component={LoginRedirect}/>
        { isAutoSaveEnabled && (<PublicRoute path="/account/dive-back-in" exact component={DiveBackInModalWrapper}/>) }
        <Route isPrivate path="/account" exact component={Home} />
      </Switch>
    </BrowserRouter>
  )
}
