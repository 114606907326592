import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { NonEmbeddedHeader, SimpleFooter, IntercomFAB } from 'av8-ui'
import { actionTypes, AuthContext } from "../contexts/Auth.context"
import useIntercomApi from '../hooks/useIntercomApi'
import { useFeatureToggle } from "../hooks/feature";

export default function Layout({ children }) {
  const history = useHistory();
  const { value: billingFlag } = useFeatureToggle('billing')
  const { appId, userHash } = useIntercomApi()
  const [ { agentName, agentEmail, agentId }, dispatch] = useContext(AuthContext)

  const handleLogout = () => {
    dispatch({ type: actionTypes.LOG_OUT })
    window.location.href = window.location.origin
  }

  const headerOptions = [{
    text: 'Log Out',
    onClick: () => handleLogout()
  }]

  if (!!billingFlag) {
    headerOptions.unshift({
      text: 'Billing',
      onClick() {
        window.location.href = billingFlag?.url ?? '/account/billing'
      }
    })
  }
  if (!/account\/?$/.test(window.location.pathname)) {
    headerOptions.unshift({
      text: 'Menu',
      onClick: () => {
        history.push('/account')
      }
    })
  }

  return (
    <div>
      { appId && userHash && agentName && agentEmail && agentId && (
        <IntercomFAB
          appId={appId}
          horizontalPadding={50}
          verticalPadding={100}
          agent={{
            name: agentName,
            email: agentEmail,
            id: agentId,
            userHash
          }}/>
      )}
      <NonEmbeddedHeader
        centralText="Home"
        menuOptions={headerOptions} />
      {children}
      <SimpleFooter isEightDetailVisible />
    </div>
  )
}
