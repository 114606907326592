import { authBaseURL } from './api'
import parseHTTPResponse from '../helpers/parseHTTPResponse'

export const getIntercomData = token => {
  return fetch(`${authBaseURL}/intercom/credentials`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    credentials: "include",
  })
    .then(parseHTTPResponse)
    .then(data => data)
}
