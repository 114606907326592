import React from 'react'

import flagsmith from 'flagsmith'; //Add this line if you're using flagsmith via npm

const environmentID = process.env.REACT_APP_FLAGSMITH_ENV_ID

let flags = {}

if (environmentID) {
  flagsmith.init({
    environmentID,
    cacheFlags: true, // stores flags in localStorage cache
    enableAnalytics: true, // See https://docs.flagsmith.com/flag-analytics/ for more info
    onChange() {
      flags = flagsmith.getAllFlags()
    },
  })
    .then(() => {
      flags = flagsmith.getAllFlags()
      if (window.av8 && window.av8.auth) {
        const authSBL = window.av8.auth.getAuthSBLInstance()
        const identify = (credentials) => {
          if (credentials && credentials.agentId) {
            flagsmith.identify(credentials.agentId)
          } else {
            flagsmith.logout()
              .then(() => {
                flags = {}
              })
          }
        }
        authSBL.addOnAuthChangeListener(identify)
        authSBL.getCredentials().then(identify)
      }
    })
}

export const useFeatureToggle = (featureName) => {
  const enabled = flags[featureName]?.enabled ?? false

  const flagValue = flags[featureName]?.value ?? false

  let value = enabled ? flagValue : false

  try {
    if (value) {
      value = JSON.parse(value)
    }
  } catch (err) {
    //
  }

  return { enabled, value }
}
