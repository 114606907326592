import React, {useReducer, createContext, useEffect, useLayoutEffect} from 'react'

export const AuthContext = createContext(undefined)

export const actionTypes = {
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  SET_LOADING: 'SET_LOADING',
  UPDATE_CREDENTIALS: 'UPDATE_CREDENTIALS',
  LOG_OUT: 'LOG_OUT',
}

const authSbl = window.av8.auth.getAuthSBLInstance()

const reducer = (state, { type, payload = {} }) => {
  const {
    agentId = state.agentId || null,
    agentName = state.agentName || null,
    agentDRE = state.agentDRE || null,
    agentEmail = state.agentEmail || null,
  } = payload

  switch (type) {
    case actionTypes.UPDATE_TOKEN:
      return { ...state, token: payload.token || null }
    case actionTypes.SET_LOADING:
      return { ...state, authLoading: !!payload.authLoading }
    case actionTypes.LOG_OUT:
      authSbl.signOut()
    case actionTypes.UPDATE_CREDENTIALS:
      return {
        ...state,
        agentId,
        agentName,
        agentDRE,
        agentEmail,
      }
    default:
      throw new Error('unknown action type')
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, undefined, () => ({
    token: null,
    authLoading: true,
    agentId: null,
    agentName: null,
    agentDRE: null,
    agentEmail: null,
  }))

  useLayoutEffect(() => {
    authSbl.getCredentials()
      .then((credentials) => {
        const { token } = credentials || {}
        dispatch({ type: actionTypes.UPDATE_TOKEN, payload: { token } })
      })
      .catch((error) => {
        console.error('Failed to get credentials', error.message, error)
        dispatch({ type: actionTypes.UPDATE_CREDENTIALS, payload: {} })
      })
      .then(() => {
        dispatch({ type: actionTypes.SET_LOADING, payload: { authLoading: false }})
      })
  }, [])

  useEffect(() => {
    const handleAuthChange = (credentials) => {
      if (credentials?.token) {
        dispatch({ type: actionTypes.UPDATE_CREDENTIALS, payload: credentials })
      }
    }
    authSbl.addOnAuthChangeListener(handleAuthChange)
    return () => {
      authSbl.removeOnAuthChangeListener(handleAuthChange)
    }
  }, [])

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  )
}
