import { NamespaceType } from "avenue8-autosave";

export const handleAutosaveIOSRedirection = (namespace, timestamp) => {
  try {
    switch (namespace) {
      case NamespaceType.Signage: {
        webkit.messageHandlers.pushSignage.postMessage({
          draft: timestamp
        })
      }
      break;
      case NamespaceType.Marketing: {
        webkit.messageHandlers.pushMarketing.postMessage({
          draft: timestamp
        })
      }
      break;
      case NamespaceType.Comps: {
        webkit.messageHandlers.pushCMA.postMessage({
          draft: timestamp
        })
      }
      break;
      default: {
        console.log('Invalid namespace');
      }
    }
  } catch (err) {
    console.log('error on autosave redirecting');
  }
}
